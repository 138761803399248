<template>
  <SideOver isLarge :title="title" :is-show="isShow" @onClose="() => onClose()" :label="content" :isLoading="isLoading" :buttons="getButtons()">
		<swiper v-if="showSwiper" @swiper="setSwiper" :simulateTouch="false" :modules="[Controller]"
						:controller="{ control: swiper }" :slides-per-view="1"
						:spaceBetween="10" :initialSlide="activeIndex && parseInt(activeIndex)" class='px-2 max-w-lg'>
			<swiper-slide class='relative'>
        <div class="flex-1 flex-col p-6 space-y-4 overflow-y-auto">
          <Textarea
              isHtml
              :label="`Describe your idea by filling in the blank.<br/>I am thinking about creating ...`"
              placeholder="i.e. a mobile app to make it easy to track physical therapy exercises at home"
              @focus="isFocusFirst = true"
              @blur="isFocusFirst = false"
              v-model:value="formObject.description"
          />
          <Input
              label="Give your idea a name for easy reference"
              placeholder="i.e. CooApp OR Accountability Booster"
              @focus="isFocusSecond = true"
              @blur="isFocusSecond = false"
              v-model:value="formObject.name"
          />
        </div>
        <div v-if="formObject.description && !isFocusFirst" class="bg-primary-700 px-6 py-4 w-full">
          <Text size="sm" weight="semibold" color="white" content="Does this sound right?"/>
          <Text size="xs" weight="" color="white" v-html="getHtml()" custom-class="mt-2"/>
        </div>
      </swiper-slide>
      <swiper-slide class='relative'>
        <div class="flex-1 flex-col p-6 space-y-4 overflow-y-auto">
          <Text size="lg" weight="medium" color="black" content="Target customer details"/>
          <Input
              label="Why needs your help?"
              placeholder="i.e. Adults in physical therapy OR Millennial moms"
              @focus="isFocusThird = true"
              @blur="isFocusThird = false"
              v-model:value="formObject.help"
          />
          <Select
            placeholder="Select type of target customer"
            label="Are they individual people, businesses, or something else ?"
            :items="customerTypeList"
            v-model:selected-item="formObject.customerType"
            custom-class="w-full"
            help-text="This will help determine how to research them and weather your potential business is B2B, B2C etc."
          />
        </div>
        <div v-if="formObject.help && !isFocusThird" class="bg-primary-700 px-6 py-4 w-full">
          <Text size="sm" weight="semibold" color="white" content="Does this sound right?"/>
          <Text size="xs" weight="" color="white" v-html="getTargetCustomerHtml()" custom-class="mt-2"/>
        </div>
      </swiper-slide>
      <swiper-slide class='relative'>
        <div class="flex-1 h-full p-6 space-y-5 overflow-y-auto">
					<div>
						<Text size="lg" weight="medium" color="black" content="Demographics, Interests, and Activities"/>
						<Text size="xs" color="gray-400" custom-class="italic"
									content="Think about the bare minimum descriptors that will define who is in your Target Customer segment and who is not. If you select more than one, you are saying MOTH can be in your Target Customer"/>
					</div>
					<AutoComplete label="Age (i.e. ONLY adults, or ONLY 55-64 years old)"
												:listData="ageList" placeholder="Start typing to filter"
												:handleSelectFn="(val) => {formObject.age = val;}"/>
					<AutoComplete label="Gender (i.e. ONLY women or ONLY non-binary)" :listData="genderList"
												placeholder="Start typing to filter" :handleSelectFn="(val) => {formObject.gender = val;}"/>
					<AutoComplete label="Race/Enthicity (i.e. ONLY of Hispanic, LatinX, or Spanish origin)"
												:listData="ethnicityList"
												placeholder="Start typing to filter" :handleSelectFn="(val) => {formObject.ethnicity = val;}"/>
					<AutoComplete label="Income (i.e. ONLY low income)" :listData="incomeList"
												placeholder="Start typing to filter" :handleSelectFn="(val) => {formObject.income = val;}"/>
					<MultiSelectDropdown label="Location" :options="locationsList" v-model:selected="formObject.location"/>
					<div>
						<MultiSelectDropdown label="Interests and Activities" :options="interestsList"
																 v-model:selected="formObject.interests"/>
						<Text size="xs" color="gray-400" custom-class="italic mt-1"
									:content="`If an interest or activity isn't on the list, you can type it in and hit &ldquo;enter&rdquo; and it will be added as a new tag.`"/>
					</div>
				</div>
      </swiper-slide>
			<swiper-slide class='relative'>
        <div class="flex-1 p-6 space-y-5 overflow-y-auto">
					<div>
						<Text size="lg" weight="medium" color="black" content="Problem"/>
						<div class="flex-1">
							<Text element="span" size="xs" color="gray-400" custom-class="italic" content="Why do "/>
							<Text element="span" size="xs" color="gray-700" custom-class="italic" :content="formObject.help"/>
							<Text element="span" size="xs" color="gray-400" custom-class="italic" content=" need your help? "/>
						</div>
					</div>
					<AutoComplete label="Because they" :listData="reasonFirstList"
								placeholder="Start typing to filter" :handleSelectFn="(val) => {formObject.reasonFirst = val;}" class="select-inline" />
					<Input
						placeholder="i.e. go to the grocery store OR manage their money"
						v-model:value="formObject.reasonSecond"
          />
					<AutoComplete label="but" :listData="reasonThirdList"
						placeholder="Start typing to filter" :handleSelectFn="(val) => {formObject.reasonThird = val;}" class="select-inline" />
					<Input
						placeholder="i.e. there aren't any store near by"
						v-model:value="formObject.reasonFour"
          />
				</div>
				<div v-if="formObject.reasonFirst && formObject.reasonFirst.label && formObject.reasonSecond && formObject.reasonThird.label" class="bg-primary-700 px-6 py-4 w-full">
          <Text size="sm" weight="semibold" color="white" content="Does this sound right?"/>
          <Text size="xs" weight="" color="white" v-html="getProblemHtml()" custom-class="mt-2"/>
        </div>
			</swiper-slide>
			<swiper-slide class='relative'>
        <div class="flex-1 h-full p-6 space-y-5 overflow-y-auto">
					<div>
						<Text size="lg" weight="medium" color="black" content="Solutions"/>
						<div class="flex-1">
							<Text element="span" size="xs" color="gray-400" custom-class="italic"
										content="how do they currently "/>
							<Text element="span" size="xs" color="gray-700" custom-class="italic"
										:content="formObject.reasonSecond" />
							<Text element="span" size="xs" color="gray-700" custom-class="italic"
										content="?" />
						</div>
					</div>
					<Input
						placeholder="i.e. drive their car OR ride the bus"
						v-model:value="solution"
					/>
					<Text @click="() => {}" class="underline cursor-pointer mr-5"
									:content="'Save and Add'" color="green-500" hoverColor="green-600" size="sm" weight="medium" />
					<Simple :list="tableList" class="flex h-auto overflow-x-auto sm:block"
					:displayHeaders="false" :displayTableOnly="true">
						<template v-slot:multiselect="{id, selectedItem, setSelectedItem}">
							<div class="w-full flex justify-center items-center h-full bg-white">
								<Text @click="() => {}" class="underline cursor-pointer mr-5"
									:content="'edit'" color="gray-500" hoverColor="gray-600" size="sm" weight="medium" />
								<Text @click="() => {}" class="underline cursor-pointer"
									:content="'remove'" color="red-500" hoverColor="red-600" size="sm" weight="medium" />
							</div>
						</template>
					</Simple>
				</div>
			</swiper-slide>
    </swiper>
  </SideOver>
</template>

<script>
import Text from '../../../atoms/Text/Text.vue'
import Icon from '../../../atoms/Icons/Icons.vue'
import Input from '../../../molecules/Inputs/Component/Component.vue'
import Textarea from '../../../molecules/Textareas/Simple/Simple'
import Select from '../../../molecules/Selects/Component/Component.vue'
import Button from '../../../atoms/Button/Button.vue'
import SideOver from '../../SideOvers/WithBackgroundOverlay/WithBackgroundOverlay.vue'
import AutoComplete from '../../../molecules/AutoComplete/AutoComplete'
import MultiSelectDropdown from "../../../molecules/Dropdown/MultiSelectDropdown/MultiSelectDropdown";
import Simple from '../../../organisms/Tables/Simple/Simple.vue';
import {ref} from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {Controller} from 'swiper';
import 'swiper/swiper.min.css';

export default {
  components: {
    Text,
    Icon,
    Input,
    Select,
    Button,
    SideOver,
		Textarea,
    Swiper,
		SwiperSlide,
    AutoComplete,
    MultiSelectDropdown,
		Simple
  },

  props: {
    isShow: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: true
    },
     isSaving: {
      type: Boolean,
      default: false
    }
  },
  watch: {
		isShow: function (val) {
			if (val) {
				setTimeout(() => this.showSwiper = true, 200)
			}
		}
	},
	methods: {
  	getHtml() {
  		return `I'm thinking about creating <span class="font-bold">${this.formObject.description}</span>. ${this.formObject.name && !this.isFocusSecond ? `For now, I'm going to call it "<span class="font-semibold">${this.formObject.name}</span>"` : ''}`
		},
    getTargetCustomerHtml() {
  		return `I want to help <span class="font-bold">${this.formObject.help}</span>. ${this.formObject?.customerType?.name ? `by creating  <span class="font-semibold">${this.formObject?.customerType?.name}</span>` : ''}`
		},
		getProblemHtml() {
				return `${this.formObject.reasonThird?.label} <span class="font-bold">${this.formObject.help}</span> to <span class="font-bold">${this.formObject.reasonSecond}</span> ${this.formObject?.reasonFour ? `because <span class="font-bold">${this.formObject?.reasonFour}</span>.` : ``}`
		},
    onClose() {
			this.$emit('onClose');
			setTimeout(() => this.showSwiper = false, 500)
		},
		onNext() {
			this.swiper.slideTo(this.activeIndex + 1);
			this.activeIndex = this.activeIndex + 1;
		},
		onBack() {
			this.swiper.slideTo(this.activeIndex - 1);
			this.activeIndex = this.activeIndex - 1;
		},
		getButtons() {
			if (this.activeIndex === 0) {
				return [
					{
						label: 'Next',
						type: 'primary',
						isLoading: this.isSaving,
            action: () => this.onSave()
					}
				]
			} else {
				return [
					{
						label: 'Back',
						type: 'secondary',
						isLoading: false,
            action: () => this.onBack()
					},
					{
						label: this.activeIndex !== 5 ? 'Next' : 'Save',
						type: 'primary',
						isLoading: this.isSaving,
            action: () => this.onSave()
					}
				]
			}
		},
		onSave() {
      if (this.activeIndex === 0 && this.formObject.name && this.formObject.description) {
        this.$emit('saveIdea', { name: this.formObject.name, blurb: this.formObject.description }, this.activeIndex);
      } else if(this.activeIndex === 1 && this.formObject.help && this.formObject?.customerType?.name) {
        this.$emit('saveIdea', { help: this.formObject.help, type: this.formObject?.customerType?.name }, this.activeIndex);
      } else if(this.activeIndex === 2 && this.formObject?.age?.label && this.formObject?.gender?.label && this.formObject?.income?.label && this.formObject?.location && this.formObject?.interests && this.formObject?.ethnicity?.label) {
        this.$emit('saveIdea', { age: this.formObject.age?.label, gender: this.formObject?.gender?.label,income: this.formObject?.income?.label,location: this.formObject?.location, interests: this.formObject?.interests, ethnicity: this.formObject?.ethnicity?.label }, this.activeIndex);
      } else if (this.activeIndex > 2) {
				this.$emit('saveIdea', {}, this.activeIndex)
			}
    }
	},
  data() {
    const swiper = ref(null);
    const setSwiper = (e) => {
      swiper.value = e;
    };
    return {
      show: false,
			isFocusFirst: true,
			isFocusSecond: true,
      isFocusThird: true,
      setSwiper,
      swiper,
      activeIndex: 0,
      showSwiper: false,
      formObject: {customerType: ''},
      customerTypeList: [
				{id: 0, name: 'INDIVIDUALS'},
				{id: 1, name: 'GROUPS'},
			],
      ageList: [
				{id: 1, label: '20-25 years'},
				{id: 2, label: '25-30 years'},
				{id: 3, label: '30-35 years'},
				{id: 4, label: '35-40 years'},
			],
			genderList: [
				{id: 1, label: 'Male'},
				{id: 2, label: 'Female'}
			],
			ethnicityList: [
				{
					id: 1,
					label: 'Asian'
				},
				{
					id: 2,
					label: 'Asian British'
				},
				{
					id: 3,
					label: 'Spanish'
				}
			],
			incomeList: [
				{
					id: 1,
					label: 'income 1'
				},
				{
					id: 2,
					label: 'income 2'
				}
			],
			locationsList: [
				'USA',
				'Mexico',
				'UK'
			],
			interestsList: [
				'Travelling',
				'Net Surfing',
				'Reading'
			],
			reasonFirstList: [
				{id: 1, label: 'need to'},
				{id: 2, label: 'have to'}
			],
			reasonThirdList: [
				{id: 1, label: `it's to difficult`},
				{id: 1, label: `it's to risky`},
			],
			tableList: [
        {Title: 'Get back to normal Life'},
        {Title: 'Heal as quickly as possible'},
        {Title: 'Stop feeling pain'},
      ],
			solution: null,
      Controller
    }
  },

  mounted() {
    setTimeout(() => {
      this.show = this.isShow;
    }, 50);
  },
}
</script>
<style scoped>
	.swiper { height: 100% }
</style>
<style>
  .select-inline {
		display: flex;
		align-items: center;
	}
	.select-inline label {
		margin-bottom: 0 !important;
    margin-right: 20px;
    font-weight: 500 !important;
	}
	.select-inline div { flex: 1 }
</style>
